<div class="navbar bg-neutral text-neutral-content">
  <div class="flex-none">
    <div class="dropdown">
      <label tabindex="0" class="btn btn-ghost btn-circle">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
          class="inline-block w-5 h-5 stroke-current"
          ><path
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-width="2"
            d="M4 6h16M4 12h16M4 18h16"
          /></svg
        >
      </label>
      <ul tabindex="0" class="menu menu-compact dropdown-content mt-3 p-2 shadow bg-neutral rounded-box w-52">
        <li><a href="#/">Home</a></li>
        <li><a href="#/music">楽曲一覧</a></li>
        <li><a href="#/ranking">総合ランキング</a></li>
      </ul>
    </div>
  </div>
  <div class="flex-1">
    <a class="btn btn-ghost normal-case text-xl" href="#/">RICORA Beats IR</a>
  </div>
  <div class="navbar-end invisible md:visible">
    <div class="flex flex-row gap-2">
      <div>
        <a href="#/music"><button class="btn btn-neutral">楽曲一覧</button></a>
      </div>
      <div>
        <a href="#/ranking"><button class="btn btn-neutral">総合ランキング</button></a>
      </div>
      <div>
        <a href="https://beats.tus-ricora.com/" target="_blank" rel="noopener noreferrer"
          ><button class="btn btn-neutral"
            ><div class="grid grid-flow-col gap-2 items-center">
              RICORA Beatsで遊ぶ<svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke-width="1.5"
                stroke="currentColor"
                class="w-5 h-5"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  d="M13.5 6H5.25A2.25 2.25 0 003 8.25v10.5A2.25 2.25 0 005.25 21h10.5A2.25 2.25 0 0018 18.75V10.5m-10.5 6L21 3m0 0h-5.25M21 3v5.25"
                />
              </svg>
            </div>
          </button></a
        >
      </div>
      <div>
        <a
          href="https://github.com/RICORA/ricora-beats/wiki"
          target="_blank"
          rel="noopener noreferrer"
          ><button class="btn btn-neutral"
            ><div class="grid grid-flow-col gap-2 items-center">
              Wiki<svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke-width="1.5"
                stroke="currentColor"
                class="w-5 h-5"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  d="M13.5 6H5.25A2.25 2.25 0 003 8.25v10.5A2.25 2.25 0 005.25 21h10.5A2.25 2.25 0 0018 18.75V10.5m-10.5 6L21 3m0 0h-5.25M21 3v5.25"
                />
              </svg>
            </div></button
          ></a
        >
      </div>
    </div>
  </div>

  <!--
  <div class="flex-none">
    <div class="dropdown dropdown-end">
      <label tabindex="0" class="btn btn-ghost btn-circle avatar">
        <div class="rounded-full">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            stroke-width="1.5"
            stroke="currentColor"
            class="w-6 h-6"
          >
            <path
              stroke-linecap="round"
              stroke-linejoin="round"
              d="M17.982 18.725A7.488 7.488 0 0012 15.75a7.488 7.488 0 00-5.982 2.975m11.963 0a9 9 0 10-11.963 0m11.963 0A8.966 8.966 0 0112 21a8.966 8.966 0 01-5.982-2.275M15 9.75a3 3 0 11-6 0 3 3 0 016 0z"
            />
          </svg>
        </div>
      </label>
      <ul
        tabindex="0"
        class="menu menu-compact dropdown-content mt-3 p-2 shadow bg-neutral rounded-box w-52"
      >
        <li><a>プロフィール</a></li>
        <li><a>アカウント設定</a></li>
        <li><a>ログイン</a></li>
      </ul>
    </div>
  </div>
  -->
</div>

<style>
  button {
    text-transform: none;
  }
</style>
