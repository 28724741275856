<div class="hero min-h-screen hero-top">
  <div class="hero-content flex-col lg:flex-row">
    <img
      src="https://user-images.githubusercontent.com/52315048/206750919-6f17d01a-fac2-4dfe-96f1-e8021f90ed78.png"
      class="sm:max-w-xs rounded-lg shadow-2xl"
      alt="RICORA Beats"
    />
    <div class="text-top">
      <h1 class="text-5xl font-bold">RICORA Beats IR</h1>
      <p class="py-6">
        Webブラウザからタッチパネルやキーボードで遊ぶ音楽ゲーム「RICORA
        Beats」とそのインターネットランキングの閲覧サイトです。
      </p>
      <a href="#/music" class="py-0.5" style="display:inline-block;"
        ><button class="btn btn-primary">楽曲別ランキング</button></a
      >
      <a href="#/ranking" class="py-0.5" style="display:inline-block;"
        ><button class="btn btn-secondary">総合ランキング</button></a
      >
      <div class="py-3">
        <a
          href="https://beats.tus-ricora.com"
          target="_blank"
          rel="noopener noreferrer"
          class="py-0.5"
          style="display:inline-block;"
          ><button class="btn btn-neutral"
            ><div class="grid grid-flow-col gap-2 items-center">
              RICORA Beatsのプレーはこちら<svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke-width="1.5"
                stroke="currentColor"
                class="w-5 h-5"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  d="M13.5 6H5.25A2.25 2.25 0 003 8.25v10.5A2.25 2.25 0 005.25 21h10.5A2.25 2.25 0 0018 18.75V10.5m-10.5 6L21 3m0 0h-5.25M21 3v5.25"
                />
              </svg>
            </div></button
          ></a
        >
        <a
          href="https://github.com/RICORA/ricora-beats/wiki"
          target="_blank"
          rel="noopener noreferrer"
          class="py-0.5"
          style="display:inline-block;"
          ><button class="btn btn-neutral"
            ><div class="grid grid-flow-col gap-2 items-center">
              RICORA Beats Wiki<svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke-width="1.5"
                stroke="currentColor"
                class="w-5 h-5"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  d="M13.5 6H5.25A2.25 2.25 0 003 8.25v10.5A2.25 2.25 0 005.25 21h10.5A2.25 2.25 0 0018 18.75V10.5m-10.5 6L21 3m0 0h-5.25M21 3v5.25"
                />
              </svg>
            </div></button
          ></a
        >
      </div>
    </div>
  </div>
</div>

<div class="hero min-h-30 bg-1">
  <div class="hero-content flex-col lg:flex-row-reverse">
    <div class="flex-row">
      <div class="py-2">
        <img
          src="https://user-images.githubusercontent.com/52315048/210119143-7ece6a92-8cd1-4b67-8460-744f099a6e54.png"
          class="sm:max-w-md rounded-lg shadow-2xl"
        />
      </div>
      <div class="py-2">
        <img
          src="https://user-images.githubusercontent.com/52315048/210119985-7a97cdd7-e0c7-47eb-a664-34d072292452.png"
          class="sm:max-w-md rounded-lg shadow-2xl"
        />
      </div>
    </div>
    <div>
      <h2 class="text-5xl font-bold text-1">RICORA Beatsとは？</h2>
      <p class="py-6 text-1">
        <a
          href="https://github.com/RICORA/ricora-beats/"
          target="_blank"
          rel="noopener noreferrer"
          class="underline hover:no-underline">RICORA Beats</a
        >は<a
          href="https://tus-ricora.com/"
          target="_blank"
          rel="noopener noreferrer"
          class="underline hover:no-underline">東京理科大学 電子計算機研究会RICORA</a
        >の有志部員によって制作された音楽(リズム)ゲームです。より多くの人にプレーしてほしいという思想の元、導入の敷居が低い<a
          href="https://developer.mozilla.org/ja/docs/Web/Progressive_web_apps"
          target="_blank"
          rel="noopener noreferrer"
          class="underline hover:no-underline">PWA</a
        >対応のWebアプリとして開発され、タッチ操作とキーボード操作のどちらでも遊べるマルチプラットフォームアプリとして設計されています。楽曲を<a
          href="https://music.tus-ricora.com/"
          target="_blank"
          rel="noopener noreferrer"
          class="underline hover:no-underline">音楽班</a
        >が、プログラムを<a
          href="https://alg.tus-ricora.com/"
          target="_blank"
          rel="noopener noreferrer"
          class="underline hover:no-underline">プログラミング班</a
        >が主に担当しています。
      </p>
      <a href="https://github.com/RICORA/ricora-beats" target="_blank" rel="noopener noreferrer"
        ><button class="btn btn-ghost glass"
          ><div class="grid grid-flow-col gap-2 items-center">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              viewBox="0 0 24 23"
              class="fill-current"
            >
              <path
                d="M 11.964844 0 C 5.347656 0 0 5.269531 0 11.792969 C 0 17.003906 3.425781 21.417969 8.179688 22.976562 C 8.773438 23.09375 8.992188 22.722656 8.992188 22.410156 C 8.992188 22.136719 8.972656 21.203125 8.972656 20.226562 C 5.644531 20.929688 4.953125 18.820312 4.953125 18.820312 C 4.417969 17.453125 3.625 17.101562 3.625 17.101562 C 2.535156 16.378906 3.703125 16.378906 3.703125 16.378906 C 4.914062 16.457031 5.546875 17.589844 5.546875 17.589844 C 6.617188 19.386719 8.339844 18.878906 9.03125 18.566406 C 9.132812 17.804688 9.449219 17.277344 9.785156 16.984375 C 7.132812 16.710938 4.339844 15.695312 4.339844 11.167969 C 4.339844 9.878906 4.8125 8.824219 5.566406 8.003906 C 5.445312 7.710938 5.03125 6.5 5.683594 4.878906 C 5.683594 4.878906 6.695312 4.566406 8.972656 6.089844 C 9.949219 5.832031 10.953125 5.703125 11.964844 5.699219 C 12.972656 5.699219 14.003906 5.835938 14.957031 6.089844 C 17.234375 4.566406 18.242188 4.878906 18.242188 4.878906 C 18.898438 6.5 18.480469 7.710938 18.363281 8.003906 C 19.136719 8.824219 19.589844 9.878906 19.589844 11.167969 C 19.589844 15.695312 16.796875 16.691406 14.125 16.984375 C 14.558594 17.355469 14.933594 18.058594 14.933594 19.171875 C 14.933594 20.753906 14.914062 22.019531 14.914062 22.410156 C 14.914062 22.722656 15.132812 23.09375 15.726562 22.976562 C 20.480469 21.414062 23.910156 17.003906 23.910156 11.792969 C 23.929688 5.269531 18.558594 0 11.964844 0 Z M 11.964844 0 "
              />
            </svg>View on GitHub
          </div></button
        ></a
      >
    </div>
  </div>
</div>

<div class="hero min-h-30 bg-2">
  <div class="hero-content flex-col lg:flex-row">
    <img
      src="https://user-images.githubusercontent.com/52315048/210120164-84ad19b2-6bda-4a44-a111-e4c762b6a7e3.png"
      class="sm:max-w-md rounded-lg shadow-2xl"
    />
    <div>
      <h2 class="text-5xl font-bold text-2">幅広い難易度と充実した譜面</h2>
      <p class="py-6 text-2">難易度は4段階、レーン数は4-7の4つから選ぶことができます。</p>
      <table>
        <tbody>
          <div class="py-2">
            <tr>
              <td>
                <img
                  src="./assets/img/diff_icon_1.png"
                  class="px-1 icon-shadow"
                  alt="BEGINNER"
                /></td
              >
              <td>
                <img
                  src="./assets/img/diff_icon_2.png"
                  class="px-1 icon-shadow"
                  alt="STANDARD"
                /></td
              >
              <td>
                <img
                  src="./assets/img/diff_icon_3.png"
                  class="px-1 icon-shadow"
                  alt="ADVANCED"
                /></td
              >
              <td>
                <img src="./assets/img/diff_icon_4.png" class="px-1 icon-shadow" alt="EXTRA" /></td
              >
            </tr>
          </div>
        </tbody>
      </table>
      <table>
        <tbody>
          <div class="py-2">
            <tr>
              <td>
                <img src="./assets/img/key_icon_4.png" class="px-1 icon-shadow" alt="4 KEYS" /></td
              >
              <td>
                <img src="./assets/img/key_icon_5.png" class="px-1 icon-shadow" alt="5 KEYS" /></td
              >
              <td>
                <img src="./assets/img/key_icon_6.png" class="px-1 icon-shadow" alt="6 KEYS" /></td
              >
              <td>
                <img src="./assets/img/key_icon_7.png" class="px-1 icon-shadow" alt="7 KEYS" /></td
              >
            </tr>
          </div>
        </tbody>
      </table>
    </div>
  </div>
</div>

<div class="hero min-h-30 bg-3">
  <div class="hero-content flex-col lg:flex-row-reverse">
    <img
      src="https://user-images.githubusercontent.com/52315048/210120049-f98f929a-8ec8-48f9-831d-2794225f568c.png"
      class="sm:max-w-md rounded-lg shadow-2xl"
    />
    <div>
      <h2 class="text-5xl font-bold text-3">インターネットランキング</h2>
      <p class="py-6 text-3">
        アカウントを作成しスコアを送信することで、世界中のプレーヤーと腕前を競うことができます。送信したベストスコアは楽曲別、総合ランキングやマイページで確認できます。
      </p>
    </div>
  </div>
</div>

<style>
  button {
    text-transform: none;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.75) !important;
  }

  .hero-top {
    background-image: url(./assets/img/demo.gif);
    background-color: rgba(0, 0, 0, 0.5);
    background-blend-mode: darken;
  }

  .text-top {
    color: #ffffff;
    text-shadow: 0 1px 3px rgba(0, 0, 0, 0.75);
  }

  .text-1 {
    color: #ffffff;
    text-shadow: 0 1px 3px rgba(0, 0, 0, 0.75);
  }
  .text-2 {
    color: #ffffff;
    text-shadow: 0 1px 3px rgba(0, 0, 0, 0.75);
  }
  .text-3 {
    color: #ffffff;
    text-shadow: 0 1px 3px rgba(0, 0, 0, 0.75);
  }

  .bg-1 {
    background-color: #f98392;
  }
  .bg-2 {
    background-color: #82bffc;
  }
  .bg-3 {
    background-color: #f9b148;
  }

  .icon-shadow {
    filter: drop-shadow(0 2px 1px rgba(0, 0, 0, 0.5));
  }
</style>
