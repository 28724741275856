export const retryFetch = async (url, options = undefined, n = 2) => {
    try {
        return await fetch(url, options);
    }
    catch (e) {
        if (n === 1)
            throw e;
        return await retryFetch(url, options, n - 1);
    }
};
